<template>
	<v-container class="mt-n6 pt-0" id="settings" fluid tag="section">
		<v-card>
			<v-card-title class="transparent-2">
				All Applications
				<v-spacer></v-spacer>
				<v-btn icon color="primary" @click="refresh()">
					<v-icon>mdi-refresh</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="pa-4">
				<v-row>
					<!-- <v-col :cols="colSize(6, 6, 12, 12)">
						<v-autocomplete
							@input="changeSelectedAgentId()"
							label="Rocket Agent"
							v-model="selectedAgent"
							:items="allAgentsList"
							:itemText="`name`"
							:itemValue="`agent.id`"
							:returnObject="true"
							outlined
							hide-details
						/>
					</v-col> -->
					<v-col :cols="colSize(6, 6, 12, 12)">
						<v-text-field
							@input="refresh()"
							label="Search"
							v-model="searchString"
							outlined
							hide-details
							clearable
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-text>
				<ApplicationsListing
					:agent_applications_list.sync="agent_applications_list"
					:agent_applications_list_pagination.sync="
						agent_applications_list_pagination
					"
					@reloadApplications="refresh()"
				/>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
	import { debounce } from "lodash";
	import { mapState } from "vuex";

	import ApplicationsListing from "@/components/tables/ApplicationsListing";
	export default {
		components: {
			ApplicationsListing,
		},
		data: () => ({
			selectedAgent: null,
			searchString: null,
			itemsPerPageOptions: [
				{
					text: 25,
					value: 25,
				},
				{
					text: 50,
					value: 50,
				},
				{
					text: 100,
					value: 100,
				},
				{
					text: "All",
					value: 99999,
				},
			],
		}),
		mounted() {
			if (this.currentUserRole == "admin") {
				this.selectedAgent = this.allAgentsList[0];
				this.changeSelectedAgentId();
			} else {
				this.selectedAgent = this.currentUser?.agent;
				this.$store.state.selected_agent_id = this.currentUser?.agent?.id;
				this.changeSelectedAgentId();
			}
		},
		computed: {
			...mapState({
				agent_applications_list: (state) =>
					state.application.agent_applications_list,
				agent_applications_list_pagination: (state) =>
					state.application.agent_applications_list_pagination,
			}),
			allAgentsList() {
				let agents_list = this.$store.getters["assignableAgentLists"];
				let allAgentsList = [
					{
						id: null,
						name: "All",
						agent: {
							agent_id: "All",
							id: null,
							user_id: null,
							payload: null,
						},
					},
				];
				allAgentsList = allAgentsList.concat(agents_list);
				return allAgentsList;
			},
			applicationsTableHeaders() {
				return [
					{
						text: "Application Id",
						align: "start",
						sortable: true,
						value: "application_id",
					},
					{ text: "Insured", value: "client.payload.first_name" },
					{ text: "Address", value: "address" },
					{ text: "Created At", value: "created_at" },
					{ text: "Status", value: "status" },
					{ text: "Type", value: "application_type" },
					{ text: "Quote", value: "min_quote_amount" },
					{ text: "Source", value: "application_source" },
				];
			},
		},
		methods: {
			refresh() {
				this.getApplicationsListDebounced();
			},
			getApplicationsListDebounced: debounce(function () {
				this.getApplicationsList();
			}, 500),
			changeSelectedAgentId() {
				this.$store.state.selected_agent_id = this.selectedAgent?.agent?.id;
				this.refresh();
			},
			async getApplicationsList() {
				let filters = { address: this.searchString };
				let application_source = ["Api Partner"];

				this.$store.dispatch("application/getAgentApplicationsList", {
					filters,
					application_source,
				});
			},

			isApplicationHasActiveAlerts(application) {
				return application?.notes?.filter(
					(note) => note?.payload?.is_alert && note?.payload?.is_active
				)?.length;
			},
			showApplicationDetails(application) {
				return {
					name: "application-details",
					params: { application_id: application.application_id },
				};
			},
		},
	};
</script>
