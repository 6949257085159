var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent ma-0 pa-0",attrs:{"flat":"","tile":"","elevation-0":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 transparent applications-table",attrs:{"sort-by":['application_id'],"sort-desc":true,"headers":_vm.applicationsTableHeaders,"items":_vm.agent_applications_list,"hide-default-footer":true,"options":{
        page: _vm.agent_applications_list_pagination.current_page,
        itemsPerPage: _vm.agent_applications_list_pagination.per_page,
      }},scopedSlots:_vm._u([{key:"item.application_id",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"black--text d-flex",attrs:{"to":_vm.showApplicationDetails(item)}},[(_vm.isApplicationHasActiveAlerts(item))?_c('v-badge',{attrs:{"color":_vm.getApplicationAlertDotColor(item),"dot":"","left":""}},[_vm._v(" "+_vm._s(item.application_id)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.application_id)+" ")])],1)]}},{key:"item.client.payload.first_name",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"black--text d-flex",attrs:{"to":_vm.showApplicationDetails(item)}},[_vm._v(" "+_vm._s(item.client.payload && item.client.payload.first_name && item.client.payload.last_name ? ((item.client.payload.first_name) + " " + (item.client.payload.last_name)) : "N/A")+" ")])]}},{key:"item.agent.user.name",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"black--text d-flex",attrs:{"to":_vm.showApplicationDetails(item)}},[_vm._v(" "+_vm._s(item.agent.user.name)+" ")])]}},{key:"item.address",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"black--text d-flex",attrs:{"to":_vm.showApplicationDetails(item)}},[_vm._v(" "+_vm._s(item.address.replace(", USA", ""))+" ")])]}},{key:"item.created_at_formatted",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"black--text d-flex",attrs:{"to":_vm.showApplicationDetails(item)}},[_vm._v(" "+_vm._s(item.created_at_formatted)+" ")])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"black--text d-flex text-capitalize",attrs:{"to":_vm.showApplicationDetails(item)}},[_vm._v(" "+_vm._s(item.status.toString().replace(/_/g, " "))+" ")])]}},{key:"item.application_type",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"black--text d-flex text-capitalize",attrs:{"to":_vm.showApplicationDetails(item)}},[_vm._v(" "+_vm._s(item.application_type ? item.application_type.toString().replace(/_/g, " ") : "flood")+" ")])]}},{key:"item.min_quote_amount",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"black--text d-flex",attrs:{"to":_vm.showApplicationDetails(item)}},[_vm._v(" "+_vm._s(item.min_quote_amount && !["0", 0, null].includes(item.min_quote_amount) ? ("$" + (item.min_quote_amount)) : "N/A")+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex",on:{"click":function($event){$event.stopPropagation();}}},[(item.status == 'complete')?[_c('v-btn',{attrs:{"color":"success","icon":""},on:{"click":function($event){return _vm.sendDownloadApplicationQuoteDialogShow(item, null, 'download')}}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.sendDownloadApplicationQuoteDialogShow(
                  item,
                  null,
                  'send-email'
                )}}},[_c('v-icon',[_vm._v("mdi-email-send-outline")])],1),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.sendDownloadApplicationQuoteDialogShow(item, null, 'send-sms')}}},[_c('v-icon',[_vm._v("mdi-cellphone-message")])],1)]:_vm._e()],2)]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"pa-1 ma-2",attrs:{"color":"primary","icon":"","small":"","dense":""},on:{"click":function($event){return _vm.reloadApplications()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('div',{staticClass:"pa-1 ma-2 v-btn--outlined rounded primary--text"},[_c('b',[_vm._v(" "+_vm._s(_vm.agent_applications_list_pagination.per_page * (_vm.agent_applications_list_pagination.current_page - 1) + 1)+" ")]),_vm._v(" - "),_c('b',[_vm._v(" "+_vm._s(_vm.agent_applications_list_pagination.per_page * (_vm.agent_applications_list_pagination.current_page - 1) + _vm.agent_applications_list.length)+" ")]),_vm._v(" of "),_c('b',[_vm._v(" "+_vm._s(_vm.agent_applications_list_pagination.total)+" ")])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 ma-2",attrs:{"color":"primary","dense":"","small":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Per Page: "),_c('b',[_vm._v(" "+_vm._s(_vm.itemsPerPageOptions.find( function (opt) { return opt.value == _vm.agent_applications_list_pagination.per_page; } ).text)+" ")]),_c('v-icon',[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageOptions),function(opt,index){return _c('v-list-item',{key:index,on:{"click":function($event){((_vm.agent_applications_list_pagination.per_page = opt.value),
                  (_vm.agent_applications_list_pagination.current_page = 1)),
                    _vm.reloadApplications()}}},[_c('v-list-item-title',[_vm._v(_vm._s(opt.text))])],1)}),1)],1),_c('v-spacer'),_c('v-pagination',{staticStyle:{"justify-content":"end"},attrs:{"length":_vm.agent_applications_list_pagination.last_page,"total-visible":5},on:{"input":function($event){return _vm.reloadApplications()}},model:{value:(_vm.agent_applications_list_pagination.current_page),callback:function ($$v) {_vm.$set(_vm.agent_applications_list_pagination, "current_page", $$v)},expression:"agent_applications_list_pagination.current_page"}})],1)]},proxy:true}])})],1),(_vm.sendDownloadApplicationQuoteDialog.dialogModel)?_c('sendDownloadApplicationQuoteDialog',_vm._b({on:{"closeDialog":function($event){return _vm.sendDownloadApplicationQuoteDialogClose()}}},'sendDownloadApplicationQuoteDialog',_vm.sendDownloadApplicationQuoteDialog,false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }