<template>
  <v-card>
    <v-card-title style="background-color: #80808029">
      {{ selectedModel ? "Update" : "Register" }}
      {{ is_agent ? "Agent" : "Admin" }}
      <v-spacer></v-spacer>
      <v-btn v-if="!is_page" @click="$emit('close-dialog')" icon color="error">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form id="form" ref="form" @submit.prevent v-model="isValidForm">
        <FormMaker :schema="form_structure" :model="form_data"></FormMaker>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="accent" outlined block @click="submit()">{{
        selectedModel ? "Update" : "Create"
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormMaker from "@/components/FormMaker";
import {
  required,
  validEmail,
  minLen,
  maxLen,
} from "@/plugins/validationRules.js";

export default {
  components: { FormMaker },
  props: {
    selectedModel: { required: true, default: null },
    is_agent: { required: true, default: true },
    is_page: { required: true, default: true },
  },
  data: () => {
    return {
      isValidForm: false,
      form_data: {
        user_id: null,
        name: null,
        username: null,
        email: null,
        zoho_enabled: "Yes",
        zoho_email: null,
        phone_number: null,
        password: null,
        image: null,
        primary_products: ["flood"],
        product_type: "flood",
        cross_products: [],
        can_see_others_applications: "No",
        display_carriers: "Yes",
      },
    };
  },
  mounted() {
    if (this.selectedModel) {
      this.form_data = JSON.parse(
        JSON.stringify(Object.assign(this.form_data, this.selectedModel.user))
      );
      this.form_data["product_type"] = this.selectedModel.assigned_product_type;
      this.form_data["zoho_enabled"] =
        this.selectedModel.payload.zoho_enabled || "Yes";
      this.form_data["zoho_email"] = this.selectedModel.zoho_email;
      this.form_data["user_id"] = this.selectedModel.user_id;

      // console.log(this.form_data, this.selectedModel);

      if (this.selectedModel?.user?.user_accesses?.payload) {
        this.form_data = Object.assign(
          this.form_data,
          this.selectedModel.user.user_accesses.payload
        );
      } else {
        this.form_data["primary_products"] = [
          this.selectedModel.assigned_product_type,
        ];
      }
    } else {
    }
  },
  computed: {
    form_structure() {
      return {
        name: {
          type: "text",
          name: "name",
          class: "custom-style",
          label: "Name",
          outlined: true,
          flat: true,
          solo: true,
          clearable: true,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: [required("Name is required."), minLen(3)],
        },
        // username: {
        //   type: "text",
        //   name: "username",
        //   label: "Username",
        //   clearable: true,
        //   tooltips: "Tooltips.",
        //   col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        //   rules: [
        //     required("Username is required."),
        //     validUsername("Invalid Username."),
        //   ],
        // },
        email: {
          type: "text",
          name: "email",
          class: "custom-style",
          label: "Email",
          outlined: true,
          solo: true,
          flat: true,
          clearable: true,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: [validEmail("Must be a valid Email."), minLen(3)],
        },
        phone_number: {
          name: "phone_number",
          type: "text",
          label: "Phone Number",
          mask: "###-###-####",
          outlined: true,
          solo: true,
          flat: true,
          clearable: true,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: [
            required("Phone Number is required."),
            minLen(12, "10 digits required."),
          ],
        },
        password: {
          type: "password",
          name: "new-password",
          class: "custom-style",
          label: "Password",
          outlined: true,
          solo: true,
          flat: true,
          clearable: true,
          autocomplete: true,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: this.selectedModel
            ? [minLen(8)]
            : [required("Password is required."), minLen(8)],
        },
        zoho_enabled: {
          type: "btn-toggle",
          name: "zoho_enabled",
          label: "Send Agent applications to Zoho.",
          ...this.$helpers.formFieldsBasicLayout,
          options: ["Yes", "No"],
          multiple: false,
          mandatory: false,
          class: "custom-style py-0 my-0 px-2 mb-2",
          rules: [required],
        },
        zoho_email: {
          hidden: this.form_data.zoho_enabled == "No",
          type: "text",
          name: "zoho_email",
          class: "custom-style",
          label: "Zoho Email",
          outlined: true,
          solo: true,
          flat: true,
          clearable: true,
          col: { cols: 12, sm: 12, smd: 12, lg: 12, xl: 12 },
          rules: [validEmail("Must be a valid Email."), minLen(3)],
        },
        primary_products: {
          type: "btn-toggle",
          name: "primary_products",
          label: "This User Will have access to the following products.",
          ...this.$helpers.formFieldsBasicLayout,
          options: this.$store.state.application.application_types.map(
            (type) => {
              return type.value;
            }
          ),
          multiple: true,
          mandatory: false,
          class: "custom-style py-0 my-0 px-2 mb-2",
          rules: [required],
        },
        product_type: {
          type: "btn-toggle",
          name: "product_type",
          label: "Base Product Type",
          ...this.$helpers.formFieldsBasicLayout,
          options: this.$store.state.application.application_types.map(
            (type) => {
              return type.value;
            }
          ),
          // .filter(type => {
          // 	return !!this.form_data.user_accesses.primary_products.includes(
          // 		type
          // 	);
          // }),
          multiple: false,
          mandatory: false,
          class: "custom-style py-0 my-0 px-2 mb-2",
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: [required],
        },
        cross_products: {
          type: "btn-toggle",
          name: "cross_products",
          label: "Active Cross Sale Products.",
          ...this.$helpers.formFieldsBasicLayout,
          options: this.$store.state.application.application_types.map(
            (type) => {
              return type.value;
            }
          ),
          multiple: true,
          mandatory: false,
          class: "custom-style py-0 my-0 px-2 mb-2",
          rules: [],
        },
        can_see_others_applications: {
          type: "btn-toggle",
          name: "can_see_others_applications",
          label: "Can see others applications?",
          ...this.$helpers.formFieldsBasicLayout,
          options: ["Yes", "No"],
          multiple: false,
          mandatory: false,
          class: "custom-style py-0 my-0 px-2 mb-2",
          rules: [required],
        },
        display_carriers: {
          type: "btn-toggle",
          name: "display_carriers",
          label: "Display Carriers?",
          ...this.$helpers.formFieldsBasicLayout,
          options: ["Yes", "No"],
          multiple: false,
          mandatory: false,
          class: "custom-style py-0 my-0 px-2 mb-2",
          rules: [required],
        },
      };
    },
  },
  watch: {
    "form_data.user_accesses.primary_products"(newVal, oldVal) {
      if (newVal?.length != newVal?.length) {
        // this.form_data.product_type = null;
        // this.form_data["user_accesses"]["cross_products"] = [];
      }
    },
  },
  methods: {
    submit() {
      var $this = this;
      this.$refs.form.validate();
      if (!this.isValidForm) return;

      let data = JSON.parse(JSON.stringify(this.form_data));

      data["user_accesses"] = {};
      data["user_accesses"]["primary_products"] =
        this.form_data.primary_products;
      data["user_accesses"]["product_type"] = this.form_data.product_type;
      data["user_accesses"]["cross_products"] = this.form_data.cross_products;
      data["user_accesses"]["can_see_others_applications"] =
        this.form_data.can_see_others_applications;
      data["user_accesses"]["display_carriers"] =
        this.form_data.display_carriers;

      if (this.selectedModel) {
        return this.post(
          `${$this.baseUrl}/update-user-account`,
          data,
          true
        ).then((data) => {
          if (data == "undefined") return;
          this.showMessageFromResponseDta(data);

          if (data.code == 200) {
            $this.$emit("registered", data);
            if ($this.is_page) {
              // $this.$router.replace({ name: "dashboard-home" });
            }
          }
        });
      }
      return this.post(
        `${$this.baseUrl}/${
          this.is_agent ? "register-agent" : "register-admin"
        }`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          $this.$emit("registered", data);
          if ($this.is_page) {
            // $this.$router.replace({ name: "dashboard-home" });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-custom-title {
  display: none;
}
</style>
