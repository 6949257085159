<template>
	<section class="pa-2">
		<v-row justify="center" align="center">
			<v-col v-if="currentUserRole == 'admin'" cols="12" md="12">
				<AgentStats />
			</v-col>
			<v-col v-if="currentUserRole == 'customer'" cols="12" md="12">
				<CustomerApplicationListing />
			</v-col>
		</v-row>
	</section>
</template>

<script>
	import AgentStats from "@/components/tables/agent-stats";
	import CustomerApplicationListing from "@/components/tables/CustomerApplicationListing";
	export default {
		components: {
			AgentStats,
			CustomerApplicationListing
		},
		head: {
			title: function() {
				return {
					inner: "Dashboard"
				};
			}
		},
		data() {
			return {};
		},
		created() {},
		methods: {}
	};
</script>
